import {KeyboardEvent, MouseEvent, useEffect, useRef} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';

import './Modal.scss';
import ModalContent from './ModalContent';

export default function Modal() {
  const dispatch = useSetGlobalState();
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const modalState = useGlobalState((state) => state.modal);
  const {isOpen, allowOverflow} = modalState;

  useEffect(() => {
    backdropRef.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setGlobalNotification(null));
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const closeModal = () => {
    const payload = {isOpen: false, component: null};
    dispatch(toggleModalAC(payload));
  };

  const clickHandler = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    closeModal();
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.code !== 'Escape') return;

    closeModal();
  };

  return isOpen ? (
    <div className="modal">
      <div
        className="modal__backdrop"
        ref={backdropRef}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          clickHandler(e);
        }}
        onKeyDown={(e) => {
          keyDownHandler(e);
        }}
      >
        <div className={`modal__content ${allowOverflow && 'modal__content--overflow'}`}>
          <ModalContent />
        </div>
      </div>
    </div>
  ) : null;
}
