import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';

import {UserActions} from '../actions';

export function getUsersACS(payload: GetUsersDTO) {
  return {
    type: UserActions.GET_USERS,
    payload,
  };
}
