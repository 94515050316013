import {GetRemaindersDTO} from 'typescript/dto/RemainderDTO';
import {Remainder} from 'typescript/models/Remainder';

import {ProjectActions} from '../../actions';

export const getRemaindersACS = (payload: GetRemaindersDTO) => {
  return {type: ProjectActions.GET_REMAINDERS, payload};
};

export const setRemaindersRedux = (payload: Remainder[]) => {
  return {type: ProjectActions.SET_REMAINDERS_LIST, payload};
};
