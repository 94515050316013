import {call, put} from 'redux-saga/effects';
import {updateInstitutionLogo, updateInstitutionName} from 'services/institutionService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IUpdateInstitutionLogo, IUpdateInstitutionName} from 'typescript/interfaces/IInstitutionActions';

import {getInstitutionAC} from '../get/actionCreator';

export function* updateInstitutionNameSaga({payload}: IUpdateInstitutionName) {
  try {
    yield call(updateInstitutionName, payload);

    yield put(getInstitutionAC());
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}

export function* updateInstitutionLogoSaga({payload}: IUpdateInstitutionLogo) {
  try {
    yield call(updateInstitutionLogo, payload);

    yield put(getInstitutionAC());
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
