import {takeLatest} from '@redux-saga/core/effects';

import {UserActions} from './actions';
import {deleteUsersSaga} from './deleteUser/saga';
import {getUsersSaga} from './getUsers/saga';
import {searchUserSaga} from './searchUser/saga';
import {disableUsersSaga} from './setUserActivity/Disable/disableUsersSaga';
import {enableUsersSaga} from './setUserActivity/Enable/enableUserSaga';
import {updateUserSaga} from './updateUser/saga';

export default function* userSaga() {
  yield takeLatest(UserActions.GET_USERS, getUsersSaga);
  yield takeLatest(UserActions.DISABLE_USER, disableUsersSaga);
  yield takeLatest(UserActions.ENABLE_USER, enableUsersSaga);
  yield takeLatest(UserActions.UPDATE_USER, updateUserSaga);
  yield takeLatest(UserActions.DELETE_USER, deleteUsersSaga);
  yield takeLatest(UserActions.SEARCH_USER, searchUserSaga);
}
