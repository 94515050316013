import {ISetArchiveLoading} from 'typescript/interfaces/IArchiveActions';

import {ArchiveActions} from '../actions';

export function setArchiveLoadingAC(payload: ISetArchiveLoading['payload']): ISetArchiveLoading {
  return {
    type: ArchiveActions.SET_ARCHIVE_LOADING,
    payload,
  };
}
