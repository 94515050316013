import {takeLatest} from '@redux-saga/core/effects';

import {NotificationActions} from './actions';
import {getNotificationCountSaga} from './getNotificationCount/saga';
import {getNotificationsSaga} from './getNotifications/saga';
import {markAllAsReadProjectSaga} from './markAllAsReadProject/saga';
import {markAsReadUnreadSaga} from './markAsRead/saga';

export default function* notificationsSaga() {
  yield takeLatest(NotificationActions.GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(NotificationActions.MARK_AS_READ, markAsReadUnreadSaga);
  yield takeLatest(NotificationActions.GET_NOTIFICATION_COUNT, getNotificationCountSaga);
  yield takeLatest(NotificationActions.MARK_ALL_AS_READ_PROJECT, markAllAsReadProjectSaga);
}
