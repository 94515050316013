import {ISetArchivePagination} from 'typescript/interfaces/IArchiveActions';

import {ArchiveActions} from '../actions';

export function setArchivePaginationAC(payload: ISetArchivePagination['payload']): ISetArchivePagination {
  return {
    type: ArchiveActions.SET_ARCHIVE_PAGINATION,
    payload,
  };
}
