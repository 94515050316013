import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {searchDeletedProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {ISearchArchiveProjects} from 'typescript/interfaces/IArchiveActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {setDeletedProjectsAC} from '../setDeletedProjects/actionCreator';
import {setDeletedProjectsLoadingAC} from '../setDeletedProjectsLoading/actionCreator';
import {setDeletedProjectsPaginationAC} from '../setDeletedProjectsPagination/actionCreator';

export function* searchDeletedProjectsSaga({payload}: ISearchArchiveProjects) {
  try {
    yield put(setDeletedProjectsLoadingAC(true));

    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(searchDeletedProjects, payload);
    yield put(setDeletedProjectsAC(response.data.content));
    const {last, first} = response.data;

    yield put(setDeletedProjectsAC(response.data.content));
    yield put(setDeletedProjectsPaginationAC({last, first}));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  } finally {
    yield put(setDeletedProjectsLoadingAC(false));
  }
}
