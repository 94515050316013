import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {CreateCommentDTO} from 'typescript/dto/CreateCommentDTO';
import {EditCommentDTO} from 'typescript/dto/EditCommentDTO';
import {Comment} from 'typescript/models/Comment';
import httpClient from 'utils/httpClient/httpClient';

export function getProjectComments(id: number): AxiosPromise<Comment[]> {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${id}/comment?order=DESC&sort=time`);
}

export function createProjectComment(id: number, data: CreateCommentDTO): AxiosPromise<Comment> {
  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/${id}/comment`, data);
}

export function deleteProjectComment(projectId: number, commentId: number): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${projectId}/comment/${commentId}`);
}

export function editProjectComment(projectId: number, commentId: number, data: EditCommentDTO): AxiosPromise<Comment> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${projectId}/comment/${commentId}`, data);
}
