import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getNotificationCount} from 'services/notificationService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetNotificationCount} from 'typescript/interfaces/INotifications';
import {NotificationCount} from 'typescript/models/Notifications';

import {setNotificationCountRedux} from './actionCreators';

export function* getNotificationCountSaga({payload}: IGetNotificationCount) {
  try {
    const response: AxiosResponse<NotificationCount> = yield call(getNotificationCount);

    yield put(setNotificationCountRedux(response.data));

    const unreadNotifications = payload.filter((notification) => !notification.read).length;
    const readNotifications = payload.length - unreadNotifications;

    yield put(
      setNotificationCountRedux({
        READ: readNotifications,
        ALL: response.data.ALL,
        UNREAD: unreadNotifications,
      }),
    );
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
