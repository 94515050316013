import {AxiosResponse} from 'axios';
import {call, put, select} from 'redux-saga/effects';
import {enableUser} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {getUsersACS} from 'store/user/getUsers/actionCreator';
import {IEnableUserModel} from 'typescript/interfaces/IUserActions';
import {isRequestSuccessful} from 'utils/helpers/isRequestSuccessful';

export function* enableUsersSaga({payload}: IEnableUserModel) {
  const {userFilters} = yield select((state) => state.users);
  try {
    const response: AxiosResponse<string> = yield call(enableUser, payload.id!);
    if (isRequestSuccessful(response)) {
      yield put(
        setGlobalNotification({
          variant: 'success',
          message: 'User activated successfully',
          icon: 'fa-user',
        }),
      );

      yield put(getUsersACS(userFilters));
      yield put(toggleModalAC({isOpen: false, component: null}));
    }
  } catch (error) {
    yield put(setGlobalNotification({variant: 'error', message: 'User activated unsuccessfully'}));
  }
}
