import {takeLatest} from '@redux-saga/core/effects';

import {AuthActions} from './actions';
import {checkForUsersSaga} from './checkForUser/saga';
import {getCurrentUserSaga} from './getCurrentUser/saga';
import {loginSaga} from './login/saga';
import {logoutSaga} from './logout/saga';

export default function* authSaga() {
  yield takeLatest(AuthActions.LOGIN_USER, loginSaga);
  yield takeLatest(AuthActions.LOGOUT, logoutSaga);
  yield takeLatest(AuthActions.CHECK_FOR_API_SET_UP, checkForUsersSaga);
  yield takeLatest(AuthActions.GET_CURRENT_USER, getCurrentUserSaga);
}
