import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getActivityLogs} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetActivityLogs} from 'typescript/interfaces/IProjectActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {ActivityLog} from 'typescript/models/ActivityLog';

import {setActivityLogRedux} from './actionCreators';

export function* getActivityLogsSaga({payload}: IGetActivityLogs) {
  try {
    const response: AxiosResponse<ResponseWithFilters<ActivityLog[]>> = yield call(getActivityLogs, payload);
    yield put(setActivityLogRedux(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
