import {SingleProject} from 'typescript/models/SingleProject';

import {ArchiveActions} from '../actions';

export const setArchivedProjectsAC = (payload: SingleProject[]) => {
  return {
    type: ArchiveActions.SET_ARCHIVED_PROJECTS,
    payload,
  };
};
