import {AxiosResponse} from 'axios';
import {put, call} from 'redux-saga/effects';
import {getProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetArchivedProjects} from 'typescript/interfaces/IArchiveActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {setArchivedProjectsAC} from '../setArchivedProjects/actionCreator';
import {setArchiveLoadingAC} from '../setArchiveLoadingAC/actionCreator';
import {setArchivePaginationAC} from '../setArchivePagination/actionCreator';

export function* getArchivedProjects(action: IGetArchivedProjects) {
  try {
    yield put(setArchiveLoadingAC(true));
    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(getProjects, {
      ...action.payload,
      active: false,
    });
    const {last, first, totalPages} = response.data;

    yield put(setArchivedProjectsAC(response.data.content));

    yield put(setArchivePaginationAC({last, first, totalPages}));
    yield put(setArchiveLoadingAC(false));
  } catch (error) {
    handleErrorsSaga(error);
  }
}
