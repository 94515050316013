export enum TagActions {
  GET_TAGS = 'GET_TAGS',
  SET_TAGS = 'SET_TAGS',
  GET_TAG_DETAILS = 'GET_TAG_DETAILS',
  SET_TAG_DETAILS = 'SET_TAG_DETAILS',
  SET_TAGS_PAGINATION = 'SET_TAGS_PAGINATION',
  SET_TAG_FILTERS = 'SET_TAG_FILTERS',
  SET_CHECKED_TAGS = 'SET_CHECKED_TAGS',
  SEARCH_TAGS = 'SEARCH_TAGS',
  SET_DELETE_TAG_ID = 'SET_DELETE_TAG_ID',
}
