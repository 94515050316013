export enum DeletedProjectsActions {
  GET_DELETED_PROJECTS = 'GET_DELETED_PROJECTS',
  SET_DELETED_CHECKED_PROJECTS = 'SET_DELETED_CHECKED_PROJECTS',
  SET_DELETED_PROJECTS_LOADING = 'SET_DELETED_PROJECTS_LOADING',
  SET_DELETED_PROJECTS_PAGINATION = 'SET_DELETED_PROJECTS_PAGINATION',
  SET_DELETED_PROJECTS_FILTERS = 'SET_DELETED_PROJECTS_FILTERS',
  SET_DELETED_PROJECTS = 'SET_DELETED_PROJECTS',
  SET_CHECKED_DELETE_TAGS = 'SET_CHECKED_DELETE_TAGS',
  SET_DELETED_PROJECT_ID = 'SET_DELETED_PROJECT_ID',
  SEARCH_DELETED_PROJECTS = 'SEARCH_DELETED_PROJECTS',
}
