import {Routes} from 'constants/routes';

import {Redirect, Route, RouteProps, useLocation} from 'react-router';

export type PrivateRouteProps = {} & RouteProps;

export default function PrivateRoute({...routeProps}: PrivateRouteProps) {
  const currentLocation = useLocation();
  const token = localStorage.getItem('token');

  return token ? <Route {...routeProps} /> : <Redirect to={{pathname: Routes.HOME, state: {from: currentLocation}}} />;
}
