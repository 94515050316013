import produce, {Draft} from 'immer';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {ModalReducerActions} from 'typescript/interfaces/IModalActions';

import {ModalActions} from './actions';

export interface IState {
  isOpen: boolean;
  component: ModalComponents | null;
  allowOverflow: boolean;
}

export const initialState: IState = {
  isOpen: false,
  component: null,
  allowOverflow: false,
};

const modalReducer = (state = initialState, action: ModalReducerActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case ModalActions.TOGGLE_MODAL:
        {
          const {isOpen, component, allowOverflow = false} = action.payload;

          draft.isOpen = isOpen;
          draft.component = component;
          draft.allowOverflow = allowOverflow;
          if (isOpen) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'auto';
          }
        }
        break;
    }
  });

export default modalReducer;
