import {NotificationContent, NotificationCount} from 'typescript/models/Notifications';

import {NotificationActions} from '../actions';

export const getNotificationCountACS = (payload: NotificationContent[]) => {
  return {type: NotificationActions.GET_NOTIFICATION_COUNT, payload};
};

export const setNotificationCountRedux = (payload: NotificationCount) => {
  return {type: NotificationActions.SET_NOTIFICATION_COUNT, payload};
};
