import {IHandleServerErrorsPayload} from 'typescript/interfaces/IAppActions';

import {AppActions} from '../actions';

export function handleServerErrorsAC(payload: IHandleServerErrorsPayload) {
  return {
    type: AppActions.HANDLE_SERVER_ERRORS,
    payload,
  };
}
