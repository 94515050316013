import {
  IAddFavoriteProject,
  IRemoveFavoriteProject,
  ISetFavoriteProject,
  IUnsetFavoriteProject,
} from 'typescript/interfaces/IProjectActions';

import {ProjectActions} from '../actions';

export function addFavoriteProjectAC({id, filters, day}: IAddFavoriteProject['payload']): IAddFavoriteProject {
  return {
    type: ProjectActions.ADD_FAVORITE_PROJECT,
    payload: {id, filters, day},
  };
}

export function removeFavoriteProjectAC({id, filters, day}: IRemoveFavoriteProject['payload']): IRemoveFavoriteProject {
  return {
    type: ProjectActions.REMOVE_FAVORITE_PROJECT,
    payload: {id, filters, day},
  };
}

export function setFavoriteProjectAC(id: number): ISetFavoriteProject {
  return {
    type: ProjectActions.SET_FAVORITE_PROJECT,
    payload: id,
  };
}

export function unsetFavoriteProjectAC(id: number): IUnsetFavoriteProject {
  return {
    type: ProjectActions.UNSET_FAVORITE_PROJECT,
    payload: id,
  };
}
