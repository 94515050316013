/* eslint-disable function-paren-newline */
import {Routes} from 'constants/routes';

import loadable from '@loadable/component';
import GlobalNotification from 'components/GlobalNotification/GlobalNotification';
import ModalPortal from 'components/Modal/ModalPortal';
import ServerErrors from 'components/ServerErrors/ServerErrors';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from 'layouts/AppLayout/AppLayout';
import {Redirect, Route, Switch} from 'react-router-dom';
import {history} from 'utils/history/history';

import {getLoggedRouteList} from './getLoggedRouteList';
import PrivateRoute from './PrivateRoute';

const Archive = loadable(() => import(/* webpackChunkName: "Archive" */ 'pages/archive/Archive'), {
  resolveComponent: (module) => module.Archive,
});

const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ 'pages/dashboard/Dashboard'));

const DeletedProjects = loadable(
  () => import(/* webpackChunkName: "DeletedProjects" */ 'pages/deletedProjects/DeletedProjects'),
);

const ForgotPassword = loadable(
  () => import(/* webpackChunkName: "ForgotPassword" */ 'pages/ForgotPassword/ForgotPassword'),
  {
    resolveComponent: (module) => module.ForgotPassword,
  },
);

const Login = loadable(() => import(/* webpackChunkName: "Login" */ 'pages/login/Login'));

const NotFound = loadable(() => import(/* webpackChunkName: "NotFound" */ 'pages/NotFound/NotFound'), {
  resolveComponent: (module) => module.NotFound,
});

const PasswordChange = loadable(
  () => import(/* webpackChunkName: "PasswordChange" */ 'pages/PasswordChange/PasswordChange'),
  {
    resolveComponent: (module) => module.PasswordChange,
  },
);

const ApiSetupRegistration = loadable(
  () =>
    import(/* webpackChunkName: "ApiSetupRegistration" */ 'pages/register/ApiSetupRegistration/ApiSetupRegistration'),
  {
    resolveComponent: (module) => module.ApiSetupRegistration,
  },
);

const Register = loadable(() => import(/* webpackChunkName: "Register" */ 'pages/register/Register'));

const TagPage = loadable(() => import(/* webpackChunkName: "Tag" */ 'pages/tag/Tags'), {
  resolveComponent: (module) => module.TagPage,
});

const Users = loadable(() => import(/* webpackChunkName: "Users" */ 'pages/users/Users'));

export default function AppRouter() {
  const token = localStorage.getItem('token');
  getLoggedRouteList({token});

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.LOGIN} component={Login} />
        <Route path={Routes.REGISTER} component={Register} exact />
        <Route path={Routes.API_REGISTRATION_SETUP} component={ApiSetupRegistration} exact />
        <Route path={Routes.PASSWORD_CHANGE} component={PasswordChange} exact />
        <Route path={Routes.RESET_PASSWORD} component={ForgotPassword} exact />

        <Route
          path={[Routes.DASHBOARD, Routes.USERS, Routes.ARCHIVE, Routes.TAG, Routes.DELETED_PROJECTS, Routes.HOME]}
        >
          <AppLayout pageName="archive">
            <Switch>
              <Route path={Routes.HOME} exact>
                {token ? <Redirect to={Routes.DASHBOARD} /> : <Redirect to={Routes.LOGIN} />}
              </Route>
              <PrivateRoute path={Routes.DASHBOARD} exact component={Dashboard} />
              <PrivateRoute
                path={`${Routes.DASHBOARD}/:id`}
                render={({match}) => {
                  const projectId = Number(match.params.id);
                  const shouldNotRedirect = Number.isNaN(projectId);
                  if (shouldNotRedirect) return <NotFound />;
                  return <Dashboard id={projectId} />;
                }}
              />
              <PrivateRoute path={Routes.USERS} exact component={Users} />
              <PrivateRoute path={Routes.ARCHIVE} exact component={Archive} />
              <PrivateRoute path={Routes.TAG} exact component={TagPage} />
              <PrivateRoute path={Routes.DELETED_PROJECTS} exact component={DeletedProjects} />
              <Route component={NotFound} />
            </Switch>
          </AppLayout>
        </Route>
      </Switch>

      <ServerErrors />
      <GlobalNotification />
      <ModalPortal />
    </ConnectedRouter>
  );
}
