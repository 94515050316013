import cloneDeep from 'lodash/cloneDeep';
import {call, put} from 'redux-saga/effects';
import {deleteUser} from 'services/userService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IDeleteUser} from 'typescript/interfaces/IUserActions';

import {setUsersAC} from '../setUsers/actionCreator';

export function* deleteUsersSaga({payload}: IDeleteUser) {
  try {
    yield call(deleteUser, payload.id);
    const newUserList = cloneDeep(payload.listOfUsers).filter((user) => user.id !== payload.id);
    yield put(setUsersAC(newUserList));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
