import {useEffect} from 'react';

import Notification from 'components/Notification/Notification';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';

export default function GlobalNotification() {
  const appState = useGlobalState((state) => state.app);
  const dispatch = useSetGlobalState();
  const {globalNotification} = appState;

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setGlobalNotification(null));
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, globalNotification]);

  return (
    <>
      {globalNotification && (
        <Notification
          message={globalNotification.message}
          variant={globalNotification.variant}
          icon={globalNotification.icon}
        />
      )}
    </>
  );
}
