import {IGetArchivedProjects} from 'typescript/interfaces/IArchiveActions';

import {ArchiveActions} from '../actions';

export default function getArchivedProjectsACS(payload: IGetArchivedProjects['payload']): IGetArchivedProjects {
  return {
    type: ArchiveActions.GET_ARCHIVED_PROJECTS,
    payload,
  };
}
