import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {GetRemaindersDTO, PostRemainderDTO} from 'typescript/dto/RemainderDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {IDeleteRemainderDTO, Remainder} from 'typescript/models/Remainder';
import httpClient from 'utils/httpClient/httpClient';

export function getRemainders(dto: GetRemaindersDTO): AxiosPromise<ResponseWithFilters<Remainder[]>> {
  const {projectId} = dto;
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${projectId}/remainder`);
}

export function postRemainder(dto: PostRemainderDTO): AxiosPromise<Remainder> {
  const {projectId} = dto;
  delete dto.projectId;

  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/${projectId}/remainder`, dto);
}

export function deleteRemainder(data: IDeleteRemainderDTO): AxiosPromise<string> {
  const {projectId, remainderId} = data;
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${projectId}/remainder/${remainderId}`);
}
