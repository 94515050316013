import produce, {Draft} from 'immer';
import {ICommentActions} from 'typescript/interfaces/ICommentsActions';
import {Comment} from 'typescript/models/Comment';

import {CommentsActions} from './actions';

interface IState {
  comments: Comment[];
  isReplying: boolean;
  parentId: number | null;
}

const initialState: IState = {
  comments: [],
  isReplying: false,
  parentId: null,
};

const commentsReducer = (state = initialState, action: ICommentActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case CommentsActions.SET_COMMENTS:
        draft.comments = action.payload;
        break;
      case CommentsActions.SET_CREATED_COMMENT:
        draft.comments.unshift(action.payload);
        break;
      case CommentsActions.UNSET_DELETED_COMMENT:
        draft.comments = draft.comments.filter((comment) => comment.id !== action.payload);
        break;
      case CommentsActions.SET_EDITED_COMMENT:
        draft.comments = draft.comments.map((comment) => (comment.id === action.payload.id ? action.payload : comment));
        break;
      case CommentsActions.SET_IS_REPLYING:
        draft.isReplying = action.payload;
        break;
      case CommentsActions.SET_PARENT_ID:
        draft.parentId = action.payload;
        break;
    }
  });

export default commentsReducer;
