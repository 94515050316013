import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';

const queryCases = ['page', 'size', 'order', 'ids', 'name', 'sort', 'createdOn', 'color', 'includeDeleted'];

export const generateTagDetailsQuery = (dto: GetTagDetailsDTO) => {
  const convertedToMatrix = Object.entries(dto).map((item) => {
    const filteredQuery = queryCases
      .map((queryCase) => {
        let query = '';
        if (queryCase === item[0]) query += `&${queryCase}=${item[1]}`;
        return query;
      })
      .filter((x) => x !== '');
    return filteredQuery;
  });

  return convertedToMatrix.flat().join('').slice(1);
};
