import produce, {Draft} from 'immer';
import {IDocumentsActions} from 'typescript/interfaces/IDocumentsActions';
import {Document} from 'typescript/models/Document';
import {UploadedFrom} from 'typescript/types/UploadedFrom';

import {DocumentsActions} from './actions';

export interface IState {
  uploadedDocument: Document | null;
  uploadedFrom: UploadedFrom | null;
}

export const initialState: IState = {
  uploadedDocument: null,
  uploadedFrom: null,
};

const documentsReducer = (state = initialState, action: IDocumentsActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case DocumentsActions.SET_UPLOADED_DOCUMENT:
        draft.uploadedDocument = action.payload;
        break;
      case DocumentsActions.SET_UPLOADED_FROM:
        draft.uploadedFrom = action.payload;
        break;
    }
  });

export default documentsReducer;
