import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getTags} from 'services/tagService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetTagList} from 'typescript/interfaces/ITagActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {Tag} from 'typescript/models/Tag';

import {setTagsAC} from '../setTags/actionCreator';

export function* getTagsSaga({payload}: IGetTagList) {
  try {
    const response: AxiosResponse<ResponseWithFilters<Tag[]>> = yield call(getTags, payload);
    yield put(setTagsAC(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
