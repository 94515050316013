import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {combineReducers} from 'redux';
import appReducer from 'store/app/appReducer';
import authReducer from 'store/auth/authReducer';
import modalReducer from 'store/modal/modalReducer';
import projectReducer from 'store/project/projectReducer';

import archiveReducer from './archive/archiveReducer';
import commentsReducer from './comments/commentsReducer';
import deletedProjectsReducer from './deletedProjects/deletedProjectsReducer';
import documentsReducer from './documents/documentsReducer';
import institutionReducer from './institution/institutionReducer';
import notificationsReducer from './notification/notificationsReducer';
import tagReducer from './tag/tagReducer';
import userReducer from './user/userReducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    modal: modalReducer,
    project: projectReducer,
    tag: tagReducer,
    users: userReducer,
    comments: commentsReducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    archive: archiveReducer,
    deletedProjects: deletedProjectsReducer,
    institution: institutionReducer,
  });

export default rootReducer;
