import {takeLatest} from 'redux-saga/effects';

import {CommentsActions} from './actions';
import {createComment} from './create/saga';
import {deleteComment} from './delete/saga';
import {editComment} from './edit/saga';
import {getComments} from './get/saga';

export default function* commentsSaga() {
  yield takeLatest(CommentsActions.GET_COMMENTS, getComments);
  yield takeLatest(CommentsActions.CREATE_COMMENT, createComment);
  yield takeLatest(CommentsActions.DELETE_COMMENT, deleteComment);
  yield takeLatest(CommentsActions.EDIT_COMMENT, editComment);
}
