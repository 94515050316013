import {takeLatest} from 'redux-saga/effects';

import {ArchiveActions} from './actions';
import {getArchivedProjects} from './getArchivedProjects/saga';
import {searchArchiveProjectsSaga} from './searchArchiveProjects/saga';
import {unarchiveSingleProject} from './unarchiveProject/saga';

export default function* archiveSaga() {
  yield takeLatest(ArchiveActions.GET_ARCHIVED_PROJECTS, getArchivedProjects);
  yield takeLatest(ArchiveActions.UNARCHIVE_PROJECT, unarchiveSingleProject);
  yield takeLatest(ArchiveActions.SEARCH_ARCHIVE_PROJECTS, searchArchiveProjectsSaga);
}
