import {call} from 'redux-saga/effects';
import {archiveProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IArchiveProject} from 'typescript/interfaces/IProjectActions';

export function* archiveProjectSaga(action: IArchiveProject) {
  try {
    yield call(archiveProject, action.payload);
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
