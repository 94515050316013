import React from 'react';

import './AppLayout.scss';

import loadable from '@loadable/component';
import {useTranslation} from 'react-i18next';

const Navbar = loadable(() => import('components/Navbar/Navbar'));

interface IProps {
  children: React.ReactNode;
  pageName: string;
}

const AppLayout = ({children, pageName}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className={`page-${pageName}`}>
      <div className="app-layout">
        <Navbar />
        <div className="app-layout__main">
          <div className="app-layout__content">{children}</div>
        </div>
        <div className="app-layout__footer">
          <div>
            {t('copy-right-first')} © {new Date().getFullYear()} {t('copy-right-second')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
