import {call, put} from 'redux-saga/effects';
import {markAsRead, markAsUnread} from 'services/notificationService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IMarkAsReadUnreadNotifications} from 'typescript/interfaces/INotifications';

import {getNotificationsACS} from '../getNotifications/actionCreators';

export function* markAsReadUnreadSaga({payload}: IMarkAsReadUnreadNotifications) {
  try {
    if (!payload.status) yield call(markAsRead, payload.id);
    else yield call(markAsUnread, payload.id);

    yield put(getNotificationsACS({order: 'DESC', sort: 'time'}));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
