import {IGetComments, ISetComments} from 'typescript/interfaces/ICommentsActions';
import {Comment} from 'typescript/models/Comment';

import {CommentsActions} from '../actions';

export function getComments(id: number): IGetComments {
  return {
    type: CommentsActions.GET_COMMENTS,
    payload: id,
  };
}

export function setComments(comments: Comment[]): ISetComments {
  return {
    type: CommentsActions.SET_COMMENTS,
    payload: comments,
  };
}
