import {SingleProject} from 'typescript/models/SingleProject';

import {DeletedProjectsActions} from '../actions';

export function setDeletedProjectsAC(payload: SingleProject[]) {
  return {
    type: DeletedProjectsActions.SET_DELETED_PROJECTS,
    payload,
  };
}
