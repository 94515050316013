import {IGetInstitution, ISetInstitution, ISetInstitutionLogoUrl} from 'typescript/interfaces/IInstitutionActions';
import {Institution} from 'typescript/models/Institution';

import {InstitutionActions} from '../actions';

export const getInstitutionAC = (): IGetInstitution => {
  return {
    type: InstitutionActions.GET_INSTITUTION,
  };
};

export const setInstitutionAC = (institution: Institution): ISetInstitution => {
  return {
    type: InstitutionActions.SET_INSTITUTION,
    payload: institution,
  };
};

export const setInstitutionLogoUrl = (payload: string | null): ISetInstitutionLogoUrl => {
  return {
    type: InstitutionActions.SET_INSTITUTION_LOGO_URL,
    payload,
  };
};
