export enum ModalComponents {
  PROJECT = 'PROJECT',
  PROJECT_DAY_PREVIEW = 'PROJECT_DAY_PREVIEW',
  ADD_USER = 'ADD_USER',
  CONFIRM_LEAVE_PROJECT = 'CONFIRM_LEAVE_PROJECT',
  CONFIRM_ARCHIVE = 'CONFIRM_ARCHIVE',
  SUCCESS_DIALOG = 'SUCCESS_DIALOG',
  CONFIRM_USER_INVITATION = 'CONFIRM_USER_INVITATION',
  CONFIRM_REGISTER_INVITATION = 'CONFIRM_REGISTER_INVITATION',
  CONFIRM_USER_ACTIVATION_DEACTIVATION = 'CONFIRM_USER_ACTIVATION_DEACTIVATION',
  SINGLE_USER = 'SINGLE_USER',
  DELETE_USER = 'DELETE_USER',
  ADD_ORGANIZER = 'ADD_ORGANIZER',
  CONFIRM_ORGANIZER = 'CONFIRM_ORGANIZER',
  CONFIRM_REINVITE = 'CONFIRM_REINVITE',
  DELETE_TAGS = 'DELETE_TAGS',
  CONFIRM_UNDO_CALL = 'CONFIRM_UNDO_CALL',
  CONFIRM_ACTIVATE_PROJECT = 'CONFIRM_ACTIVATE_PROJECT',
  CONFIRM_DELETE_PROJECT = 'CONFIRM_DELETE_PROJECT',
  CONFIRM_EMAIL_CHANGE = 'CONFIRM_EMAIL_CHANGE',
  CONFIRM_DELETE_TAG = 'CONFIRM_DELETE_TAG',
  CONFIRM_SINGLE_TAG_DELETE = 'CONFIRM_SINGLE_TAG_DELETE',
  SEND_BULK_USERS_INVITATION = 'SEND_BULK_USERS_INVITATION',
  UNARCHIVE_BULK_ACTION = 'UNARCHIVE_BULK_ACTION',
  CONFIRM_UNARCHIVE_PROJECT = 'CONFIRM_UNARCHIVE_PROJECT',
  IMAGE_PREVIEW = 'IMAGE_PREVIEW',
}
