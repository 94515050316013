import {IGetNotficationsDTO} from 'typescript/dto/NotificationsDTO';
import {NotificationContent} from 'typescript/models/Notifications';

import {NotificationActions} from '../actions';

export const getNotificationsACS = (payload: IGetNotficationsDTO) => {
  return {type: NotificationActions.GET_NOTIFICATIONS, payload};
};

export const setNotificationsRedux = (payload: NotificationContent[]) => {
  return {type: NotificationActions.SET_NOTIFICATIONS, payload};
};
