export enum ArchiveActions {
  GET_ARCHIVED_PROJECTS = 'GET_ARCHIVED_PROJECTS',
  SET_ARCHIVED_PROJECTS = 'SET_ARCHIVED_PROJECTS',
  SET_ARCHIVE_LOADING = 'SET_ARCHIVE_LOADING',
  SET_ARCHIVE_PAGINATION = 'SET_ARCHIVE_PAGINATION',
  UNARCHIVE_PROJECT = 'UNARCHIVE_PROJECT',
  SET_ARCHIVE_FILTERS = 'SET_ARCHIVE_FILTERS',
  SET_CHECKED_ARCHIVE_PROJECTS = 'SET_CHECKED_ARCHIVE_PROJECTS',
  SEARCH_ARCHIVE_PROJECTS = 'SEARCH_ARCHIVE_PROJECTS',
  SET_CHECKED_ARCHIVE_TAGS = 'SET_CHECKED_ARCHIVE_TAGS',
}
