import {CreateCommentDTO} from 'typescript/dto/CreateCommentDTO';
import {ICreateComment, ISetCreatedComment} from 'typescript/interfaces/ICommentsActions';

import {CommentsActions} from '../actions';

export function createComment(data: CreateCommentDTO, id: number): ICreateComment {
  return {
    type: CommentsActions.CREATE_COMMENT,
    payload: {data, id},
  };
}

export function setCreatedComment(data: any): ISetCreatedComment {
  return {
    type: CommentsActions.SET_CREATED_COMMENT,
    payload: data,
  };
}
