import {call, put} from 'redux-saga/effects';
import {createCommentReaction} from 'services/reactionsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {getComments} from 'store/comments/get/actionCreator';
import {ICreateReaction} from 'typescript/interfaces/IReactionsActions';

export function* createReaction(action: ICreateReaction) {
  const {projectId} = action.payload;

  try {
    yield call(createCommentReaction, action.payload);

    yield put(getComments(projectId));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
