import {IDeleteComment, IUnsetDeletedComment} from 'typescript/interfaces/ICommentsActions';

import {CommentsActions} from '../actions';

export function deleteComment(projectId: number, commentId: number): IDeleteComment {
  return {
    type: CommentsActions.DELETE_COMMENT,
    payload: {projectId, commentId},
  };
}

export function unsetDeletedComment(id: number): IUnsetDeletedComment {
  return {
    type: CommentsActions.UNSET_DELETED_COMMENT,
    payload: id,
  };
}
