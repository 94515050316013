import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getProjectComments} from 'services/commentsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetComments} from 'typescript/interfaces/ICommentsActions';
import {Comment} from 'typescript/models/Comment';

import {setComments} from './actionCreator';

export function* getComments(action: IGetComments) {
  try {
    const response: AxiosResponse<{content: Comment[]}> = yield call(getProjectComments, action.payload);

    yield put(setComments(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
