/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {logoutAC} from 'store/auth/logout/actionCreator';
import store from 'store/store';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 418) {
      store.dispatch(logoutAC({}));
    }

    if (error?.response?.status === 401) {
      store.dispatch(setGlobalNotification({message: 'sessionExpired', variant: 'error'}));
      store.dispatch(logoutAC({}));
    }

    return Promise.reject(error);
  },
);

export default httpClient;
