import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {TagDetail} from 'typescript/models/TagDetails';

import {TagActions} from '../actions';

export const getTagDetailsACS = (payload: GetTagDetailsDTO) => {
  return {type: TagActions.GET_TAG_DETAILS, payload};
};

export const setTagDetailsRedux = (payload: TagDetail[]) => {
  return {type: TagActions.SET_TAG_DETAILS, payload};
};
