import {call, put} from 'redux-saga/effects';
import {addOrganizer, leaveProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IAddRemoveOrganizers} from 'typescript/interfaces/IProjectActions';

import {getActivityLogACS} from '../getActivityLog/actionCreators';
import {getProjectsSagaACS} from '../getProjects/actionCreator';
import {setSingleProjectAC} from '../setSingleProject/actionCreator';

export function* addRemoveOrganizersSaga({payload}: IAddRemoveOrganizers) {
  try {
    if (payload.addOrRemove) yield call(addOrganizer, payload.dto);
    else yield call(leaveProject, payload.dto);

    if (payload.singleProject) {
      yield put(setSingleProjectAC(payload.singleProject));
      yield put(getActivityLogACS(payload.singleProject.id));
    }

    if (payload.refreshProjects && payload.projectFilters) yield put(getProjectsSagaACS(payload.projectFilters));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
