import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {IGetNotficationsDTO} from 'typescript/dto/NotificationsDTO';
import {ProjectNotificationsDTO} from 'typescript/dto/ProjectNotificationsDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {NotificationContent, NotificationCount} from 'typescript/models/Notifications';
import httpClient from 'utils/httpClient/httpClient';

import {generateNotificationQuery} from './Helpers/generateNotificationsQuery';
import {generateQueryObject} from './Helpers/generateQueryObject';

export const getNotifications = (
  dto?: IGetNotficationsDTO,
): AxiosPromise<ResponseWithFilters<NotificationContent[]>> => {
  const query = generateQueryObject(dto, generateNotificationQuery);
  return httpClient.get(`${API.NOTIFICATION_SERVICE.BASE}${query}`);
};

export const markAsRead = (id: number): AxiosPromise<NotificationContent> => {
  return httpClient.put(`${API.NOTIFICATION_SERVICE.BASE}/${id}/markAsRead`);
};

export const markAllAsRead = (ids: number[]): AxiosPromise<NotificationContent> => {
  return httpClient.post(`${API.NOTIFICATION_SERVICE.BASE}/bulk/markAsRead`, {ids});
};

export const markAsUnread = (id: number): AxiosPromise<NotificationContent> => {
  return httpClient.put(`${API.NOTIFICATION_SERVICE.BASE}/${id}/markAsUnread`);
};

export const getNotificationCount = (): AxiosPromise<NotificationCount> => {
  return httpClient.get(`${API.NOTIFICATION_SERVICE.BASE}/count`);
};

export function markAllProjectAsRead({dto}: ProjectNotificationsDTO): AxiosPromise<any> {
  return httpClient.put(`${API.NOTIFICATION_SERVICE.BASE}/project/${dto.projectId}/user/${dto.userId}/markAsRead`);
}
