import {all} from 'redux-saga/effects';

import appSaga from './app/appSaga';
import archiveSaga from './archive/archiveSaga';
import authSaga from './auth/authSaga';
import commentsSaga from './comments/commentsSaga';
import deletedProjectSaga from './deletedProjects/deletedProjectsSaga';
import {documentsSaga} from './documents/documentsSaga';
import institutionSaga from './institution/institutionSaga';
import notificationsSaga from './notification/saga';
import projectSaga from './project/projectSaga';
import reactionsSaga from './reactions/reactionsSaga';
import tagSaga from './tag/tagSaga';
import userSaga from './user/userSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    appSaga(),
    projectSaga(),
    tagSaga(),
    userSaga(),
    commentsSaga(),
    reactionsSaga(),
    documentsSaga(),
    notificationsSaga(),
    archiveSaga(),
    deletedProjectSaga(),
    institutionSaga(),
  ]);
}
