import {Routes} from 'constants/routes';

import {push} from 'connected-react-router';
import {put} from 'redux-saga/effects';
import {setInstitutionAC, setInstitutionLogoUrl} from 'store/institution/get/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ILogoutUser} from 'typescript/interfaces/IAuthActions';

export function* logoutSaga(action: ILogoutUser) {
  const {shouldRedirect} = action.payload;
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  yield put(toggleModalAC({isOpen: false, component: null}));
  yield put(setInstitutionLogoUrl(null));
  yield put(
    setInstitutionAC({name: '', logo: {id: 0, name: '', comment: '', type: 'FILE', source: 'COMPUTER', url: 'null'}}),
  );
  if (!shouldRedirect) yield put(push(Routes.LOGIN));
}
