import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from '../actions';

export function setProjectsAC(payload: SingleProject[]) {
  return {
    type: ProjectActions.SET_PROJECTS,
    payload,
  };
}
