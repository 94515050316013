import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {searchArchiveProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {ISearchArchiveProjects} from 'typescript/interfaces/IArchiveActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {setArchivedProjectsAC} from '../setArchivedProjects/actionCreator';
import {setArchiveLoadingAC} from '../setArchiveLoadingAC/actionCreator';
import {setArchivePaginationAC} from '../setArchivePagination/actionCreator';

export function* searchArchiveProjectsSaga({payload}: ISearchArchiveProjects) {
  try {
    yield put(setArchiveLoadingAC(true));

    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(searchArchiveProjects, payload);
    yield put(setArchivedProjectsAC(response.data.content));
    const {last, first} = response.data;

    yield put(setArchivedProjectsAC(response.data.content));

    yield put(setArchivePaginationAC({last, first}));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  } finally {
    yield put(setArchiveLoadingAC(false));
  }
}
