import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {ICreateReaction, IDeleteReaction} from 'typescript/interfaces/IReactionsActions';
import {Reaction} from 'typescript/models/Reaction';
import httpClient from 'utils/httpClient/httpClient';

export function createCommentReaction({
  data,
  projectId,
  commentId,
}: ICreateReaction['payload']): AxiosPromise<Reaction> {
  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/${projectId}/comment/${commentId}/reaction`, data);
}

export function deleteCommentReaction({
  projectId,
  commentId,
  reactionId,
}: IDeleteReaction['payload']): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${projectId}/comment/${commentId}/reaction/${reactionId}`);
}
