import {call, put} from 'redux-saga/effects';
import {favoriteProject, unfavoriteProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {setDropdownOpenAC} from 'store/app/setDropdownOpen/actionCreator';
import {IAddFavoriteProject, IRemoveFavoriteProject} from 'typescript/interfaces/IProjectActions';

import {setFavoriteProjectAC, unsetFavoriteProjectAC} from './actionCreator';

export function* addFavoriteProject({payload}: IAddFavoriteProject) {
  try {
    yield call(favoriteProject, payload.id);

    yield put(setFavoriteProjectAC(payload.id));
    if (payload.day) yield put(setDropdownOpenAC(payload.day));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}

export function* removeFavoriteProject({payload}: IRemoveFavoriteProject) {
  try {
    yield call(unfavoriteProject, payload.id);

    yield put(unsetFavoriteProjectAC(payload.id));
    if (payload.day) yield put(setDropdownOpenAC(payload.day));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
