import {put} from 'redux-saga/effects';
import {isAxiosError} from 'typescript/typeGuards/isAxiosError';

import {handleServerErrorsAC} from '../handleServerErrors/actionCreator';

export function* handleErrorsSaga(error: unknown) {
  if (isAxiosError(error)) {
    yield put(handleServerErrorsAC(error));
  }
}
