import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {ChangePasswordDTO} from 'typescript/dto/ChangePasswordDTO';
import {CreatePasswordDTO} from 'typescript/dto/CreatePasswordDTO';
import {CreateUserDTO} from 'typescript/dto/createUserDTO';
import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';
import {LoginDTO} from 'typescript/dto/loginDTO';
import {SetupDTO} from 'typescript/dto/SetupDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {Member} from 'typescript/models/Member';
import {UpdatedUser} from 'typescript/models/UpdatedUser';
import {IUpdateUserPayload, User} from 'typescript/models/User';
import httpClient from 'utils/httpClient/httpClient';

import {generateUserQuery} from './Helpers/generateUsersQuery';

export function login({email, password}: LoginDTO): AxiosPromise<User> {
  return httpClient.post(API.USER_SERVICE.LOGIN, {email, password});
}

export const logout = (): AxiosPromise<unknown> => {
  return httpClient.get(API.USER_SERVICE.LOGOUT);
};

export function getUsers(dto?: GetUsersDTO): AxiosPromise<ResponseWithFilters<User[]>> {
  const query = dto ? `?${generateUserQuery(dto)}` : '';
  return httpClient.get(`${API.USER_SERVICE.USER}${query}`);
}

export function createUser(dto: CreateUserDTO): AxiosPromise<User> {
  return httpClient.post(API.USER_SERVICE.USER, dto);
}

export function deleteUser(id: number): AxiosPromise<string> {
  return httpClient.delete(`${API.USER_SERVICE.USER}/${id}`);
}

export function getCurrentUser(): AxiosPromise<UpdatedUser> {
  return httpClient.get(API.USER_SERVICE.GET_CURRENT_USER);
}

export function enableUser(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.USER_SERVICE.USER}/${id}/enable`);
}

export function disableUser(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.USER_SERVICE.USER}/${id}/disable`);
}

export function cancelInvitation(id: number): AxiosPromise<string> {
  return httpClient.post(`${API.USER_SERVICE.USER}/${id}/cancelInvitation`);
}

export function updateUser(payload: IUpdateUserPayload): AxiosPromise<UpdatedUser> {
  return httpClient.put(`${API.USER_SERVICE.USER}/${payload.id}`, payload.dto);
}

export function forgotPassword(email: string): AxiosPromise<string> {
  return httpClient.put(API.USER_SERVICE.FORGOT_PASSWORD, {email}, {headers: {'Content-Type': 'application/json'}});
}

export function createPassword(dto: CreatePasswordDTO): AxiosPromise<string> {
  return httpClient.put(API.USER_SERVICE.CREATE_PASSWORD, dto);
}

export function changePassword(dto: ChangePasswordDTO): AxiosPromise<string> {
  return httpClient.put(API.USER_SERVICE.CHANGE_PASSWORD, dto);
}

export function resendInvitation(id: number): AxiosPromise<string> {
  return httpClient.post(`${API.USER_SERVICE.USER}/${id}/resendInvitation`);
}

export function resendInvitationBulk(ids: number[]): AxiosPromise<string> {
  return httpClient.post(`${API.USER_SERVICE.USER}/bulk/resendInvitation`, {ids});
}

export function searchUsers(search: string): AxiosPromise<ResponseWithFilters<Member[]>> {
  return httpClient.get(`${API.SEARCH.USERS}?name=${search}`);
}

export function setupCheck(): AxiosPromise<boolean> {
  return httpClient.get('/setup/check');
}

export function createSetup(dto: SetupDTO): AxiosPromise<User> {
  return httpClient.post('/setup', dto);
}

export function getAllOrganizers(): AxiosPromise<User[]> {
  return httpClient.get(API.USER_SERVICE.GET_ALL_ORGANIZERS);
}

export function getAllMembers(): AxiosPromise<User[]> {
  return httpClient.get(API.USER_SERVICE.GET_ALL_MEMBERS);
}
