import {Routes} from 'constants/routes';

import {AxiosResponse} from 'axios';
import {push} from 'connected-react-router';
import {put, call} from 'redux-saga/effects';
import {login} from 'services/userService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {ILoginUser} from 'typescript/interfaces/IAuthActions';
import {User} from 'typescript/models/User';
import {isAxiosError} from 'typescript/typeGuards/isAxiosError';

import {setUserAC} from '../setUser/actionCreator';

export function* loginSaga(action: ILoginUser) {
  const rememberedPage = localStorage.getItem('rememberedPage');

  try {
    const {email, password} = action.payload;
    const response: AxiosResponse<User> = yield login({email, password});

    localStorage.setItem('token', response.data.accessToken);
    yield put(setUserAC(response.data));

    if (rememberedPage) {
      yield put(push(rememberedPage));
      localStorage.removeItem('rememberedPage');
    } else {
      yield put(push(Routes.DASHBOARD));
    }
  } catch (error) {
    // eslint-disable-next-line
    let status;

    if (isAxiosError(error)) status = error.response?.status;

    if (status === 401) {
      yield put(
        setGlobalNotification({message: "You don't have access or you entered wrong credentials", variant: 'error'}),
      );
    } else yield call(handleErrorsSaga, error);
  }
}
