import AppSetup from 'AppSetup/AppSetup';
import AppRouter from 'router/AppRouter';

const App = () => {
  return (
    <AppSetup>
      <AppRouter />
    </AppSetup>
  );
};

export default App;
