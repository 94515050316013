import {takeLatest} from 'redux-saga/effects';

import {DocumentsActions} from './actions';
import {deleteDocument} from './delete/saga';
import {uploadDocument} from './upload/saga';

export function* documentsSaga() {
  yield takeLatest(DocumentsActions.UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest(DocumentsActions.DELETE_DOCUMENT, deleteDocument);
}
