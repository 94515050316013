import {IGetNotficationsDTO} from 'typescript/dto/NotificationsDTO';

const queryCases = ['page', 'size', 'sort', 'order', 'read', 'ids', 'title', 'active', 'includeDeleted'];

export const generateNotificationQuery = (dto: IGetNotficationsDTO) => {
  const convertedToMatrix = Object.entries(dto).map((item) => {
    const filteredQuery = queryCases
      .map((queryCase) => {
        let query = '';
        if (queryCase === item[0]) query += `&${queryCase}=${item[1]}`;
        return query;
      })
      .filter((x) => x !== '');
    return filteredQuery;
  });

  return convertedToMatrix.flat().join('').slice(1);
};
