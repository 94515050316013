import {call, put} from 'redux-saga/effects';
import {updateProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IUpdateProject} from 'typescript/interfaces/IProjectActions';
import {prepareCreateProjectDTO} from 'utils/project/prepareCreateProjectDTO';

import {setUpdatedProjectACS} from './actionCreator';

export function* updateProjectSaga(action: IUpdateProject) {
  try {
    const project = action.payload;
    const dto = prepareCreateProjectDTO(project);

    yield call(updateProject, project.id, dto);
    yield put(setUpdatedProjectACS(project));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
