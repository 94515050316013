import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getCurrentUser} from 'services/userService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {User} from 'typescript/models/User';

import {setUserAC} from '../setUser/actionCreator';

export function* getCurrentUserSaga() {
  try {
    const response: AxiosResponse<User> = yield call(getCurrentUser);

    yield put(setUserAC(response.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
