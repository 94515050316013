import {CreateProjectDTO} from 'typescript/dto/createProjectDTO';
import {SingleProject} from 'typescript/models/SingleProject';

export function prepareCreateProjectDTO(project: SingleProject): CreateProjectDTO {
  const dto: CreateProjectDTO = {
    title: project.title,
    description: project.description,
  };

  if (project.organizers.length) {
    dto.organizerIds = project.organizers.map((organizer) => organizer.id);
  }
  if (project.members.length) {
    dto.memberIds = project.members.map((member) => member.id);
  }
  if (project.tags.length) {
    dto.tagIds = project.tags.map((x) => x.id);
  }
  if (project.date) {
    dto.date = project.date;
  }
  if (project.timeFrom) {
    dto.timeFrom = project.timeFrom;
  }
  if (project.timeTo) {
    dto.timeTo = project.timeTo;
  }

  return dto;
}
