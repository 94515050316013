import {IToggleModal, IToggleModalPayload} from 'typescript/interfaces/IModalActions';

import {ModalActions} from '../actions';

export function toggleModalAC(payload: IToggleModalPayload): IToggleModal {
  return {
    type: ModalActions.TOGGLE_MODAL,
    payload,
  };
}
