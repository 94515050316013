import {useEffect} from 'react';

import Notification from 'components/Notification/Notification';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {handleServerErrorsAC} from 'store/app/handleServerErrors/actionCreator';

export default function ServerErrors() {
  const appState = useGlobalState((state) => state.app);
  const dispatch = useSetGlobalState();
  const {axiosError} = appState;

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(handleServerErrorsAC(null));
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, axiosError]);

  return (
    <>
      {axiosError && (
        <Notification
          message={axiosError?.message ? axiosError.message : 'There was an error, please reload and try again.'}
          variant="error"
        />
      )}
    </>
  );
}
