import {PaginateData} from 'typescript/models/Paginations';

import {UserActions} from '../actions';

export const paginateUsersAC = (payload: PaginateData) => {
  return {
    type: UserActions.SET_PAGINATE_USERS,
    payload,
  };
};
