import {call, put} from 'redux-saga/effects';
import {deleteProject} from 'services/projectService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {IDeleteProject} from 'typescript/interfaces/IProjectActions';

import {getProjectsSagaACS} from '../getProjects/actionCreator';

export function* deleteProjectSaga({payload}: IDeleteProject) {
  try {
    yield call(deleteProject, payload.id);
    yield put(getProjectsSagaACS(payload.filters));
    yield put(toggleModalAC({isOpen: false, component: null}));
    yield put(
      setGlobalNotification({
        variant: 'success',
        message: 'Project successfully deleted',
        icon: 'fa-credit-card-blank',
      }),
    );
  } catch (error) {
    yield put(setGlobalNotification({variant: 'error', message: 'Project unsuccessfully deleted'}));
  }
}
