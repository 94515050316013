import produce, {Draft} from 'immer';
import {IAuthActions} from 'typescript/interfaces/IAuthActions';
import {User} from 'typescript/models/User';

import {AuthActions} from './actions';

export interface IState {
  user: User | null;
  isAPISet: boolean;
}

export const initialState: IState = {
  user: null,
  isAPISet: false,
};

const authReducer = (state = initialState, action: IAuthActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case AuthActions.SET_USER:
        draft.user = action.payload;
        localStorage.setItem('user', JSON.stringify(action.payload));
        break;
      case AuthActions.IS_API_SET_UP:
        draft.isAPISet = action.payload;
        break;
    }
  });

export default authReducer;
