export const Routes = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  REGISTER: '/verify-email',
  USERS: '/users',
  ARCHIVE: '/archive',
  TAG: '/tag',
  DELETED_PROJECTS: '/deleted-projects',
  API_REGISTRATION_SETUP: '/first-time-setup',
  PASSWORD_CHANGE: '/change-password',
  RESET_PASSWORD: '/reset-password',
};
