import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

import {ProjectActions} from '../actions';

export function getProjectsSagaACS(payload: IGetProjectsDTO) {
  return {
    type: ProjectActions.GET_PROJECTS,
    payload,
  };
}
