/* eslint-disable function-paren-newline */
import loadable from '@loadable/component';
import {useGlobalState} from 'hooks/useGlobalState';
import {ModalComponents} from 'typescript/enums/ModalComponents';

const AddOrganizer = loadable(() => import(/* webpackChunkName: "AddOrganizer" */ './AddOrganizer/AddOrganizer'));
const AddUser = loadable(() => import(/* webpackChunkName: "AddUser" */ './AddUser/AddUser'));
const ConfirmActivateProject = loadable(() =>
  import(/* webpackChunkName: "ConfirmActivateProject" */ './ConfirmActivateProject/ConfirmActivateProject').then(
    (module) => ({
      default: module.ConfirmActivateProject,
    }),
  ),
);
const ConfirmArchive = loadable(
  () => import(/* webpackChunkName: "ConfirmArchive" */ './ConfirmArchive/ConfirmArchive'),
);
const ConfirmDeleteProject = loadable(() =>
  import(/* webpackChunkName: "ConfirmDeleteProject" */ './ConfirmDeleteProject/ConfirmDeleteProject').then(
    (module) => ({
      default: module.ConfirmDeleteProject,
    }),
  ),
);
const ConfirmDeleteUser = loadable(() =>
  import(/* webpackChunkName: "ConfirmDeleteUser" */ './ConfirmDeleteUser/ConfirmDeleteUser').then((module) => ({
    default: module.ConfirmDeleteUser,
  })),
);
const ConfirmEmailChange = loadable(() =>
  import(/* webpackChunkName: "ConfirmEmailChange" */ './ConfirmEmailChange/ConfirmEmailChange').then((module) => ({
    default: module.ConfirmEmailChange,
  })),
);
const ConfirmLeaveProject = loadable(
  () => import(/* webpackChunkName: "ConfirmLeaveProject" */ './ConfirmLeaveProject/ConfirmLeaveProject'),
);
const ConfirmOrganizer = loadable(
  () => import(/* webpackChunkName: "ConfirmOrganizer" */ './ConfirmOrganizer/ConfirmOrganizer'),
);
const ConfirmRegister = loadable(
  () => import(/* webpackChunkName: "ConfirmRegister" */ './ConfirmRegister/ConfirmRegister'),
);
const ConfirmUnarchiveProject = loadable(
  () => import(/* webpackChunkName: "ConfirmUnarchiveProject" */ './ConfirmUnarchiveProject/ConfirmUnarchiveProject'),
);
const ConfirmUnsend = loadable(() =>
  import(/* webpackChunkName: "ConfirmUnsend" */ './ConfirmUnsend/ConfirmUnsend').then((module) => ({
    default: module.ConfirmUnsend,
  })),
);
const ConfirmUserActivationDeactivaiton = loadable(() =>
  import(
    /* webpackChunkName: "ConfirmUserActivationDeactivaiton" */ './ConfirmUserActivationDeactivaiton/ConfirmUserActivationDeactivaiton'
  ).then((module) => ({
    default: module.ConfirmUserActivationDeactivaiton,
  })),
);
const ConfirmUserInvitation = loadable(
  () => import(/* webpackChunkName: "ConfirmUserInvitation" */ './ConfirmUserInvitation/ConfirmUserInvitation'),
);
const ConfirmUserReinvite = loadable(() =>
  import(/* webpackChunkName: "ConfirmUserReinvite" */ './ConfirmUserReinvite/ConfirmUserReinvite').then((module) => ({
    default: module.ConfirmUserReinvite,
  })),
);
const DeleteAndUndeletable = loadable(
  () => import(/* webpackChunkName: "DeleteAndUndeletable" */ './DeleteTags/DeleteAndUndeletable'),
);
const DeleteSingleTag = loadable(() =>
  import(/* webpackChunkName: "DeleteSingleTag" */ './DeleteTags/DeleteSingleTag').then((module) => ({
    default: module.DeleteSingleTag,
  })),
);
const DeleteTag = loadable(() =>
  import(/* webpackChunkName: "DeleteTag" */ './DeleteTags/DeleteTag').then((module) => ({default: module.DeleteTag})),
);
const Project = loadable(() => import(/* webpackChunkName: "Project" */ './Project/Project'));
const ProjectDayPreview = loadable(
  () => import(/* webpackChunkName: "ProjectDayPreview" */ './ProjectDayPreview/ProjectDayPreview'),
);
const SendBulkUsersInvitation = loadable(
  () => import(/* webpackChunkName: "SendBulkUsersInvitation" */ './SendBulkUsersInvitation/SendBulkUsersInvitation'),
);
const SingleUser = loadable(() =>
  import(/* webpackChunkName: "SingleUser" */ './SingleUser/SingleUser').then((module) => ({
    default: module.SingleUser,
  })),
);
const SuccessDialog = loadable(() => import(/* webpackChunkName: "SuccessDialog" */ './SuccessDialog/SuccessDialog'));
const UnarchiveBulkAction = loadable(
  () => import(/* webpackChunkName: "UnarchiveBulkAction" */ './UnarchiveBulkAction/UnarchvieBulkAction'),
);
const ImagePreview = loadable(
  () => import(/* webpackChunkName: "ImagePreview" */ './Project/ImagePreview/ImagePreview'),
);

export default function ModalContent() {
  const modalState = useGlobalState((state) => state.modal);

  const renderModalContent = (component: ModalComponents | null) => {
    switch (component) {
      case ModalComponents.PROJECT:
        return <Project />;
      case ModalComponents.PROJECT_DAY_PREVIEW:
        return <ProjectDayPreview />;
      case ModalComponents.ADD_USER:
        return <AddUser />;
      case ModalComponents.SUCCESS_DIALOG:
        return <SuccessDialog />;
      case ModalComponents.CONFIRM_USER_INVITATION:
        return <ConfirmUserInvitation />;
      case ModalComponents.CONFIRM_LEAVE_PROJECT:
        return <ConfirmLeaveProject />;
      case ModalComponents.CONFIRM_ARCHIVE:
        return <ConfirmArchive />;
      case ModalComponents.CONFIRM_REGISTER_INVITATION:
        return <ConfirmRegister />;
      case ModalComponents.CONFIRM_USER_ACTIVATION_DEACTIVATION:
        return <ConfirmUserActivationDeactivaiton />;
      case ModalComponents.SINGLE_USER:
        return <SingleUser />;
      case ModalComponents.DELETE_USER:
        return <ConfirmDeleteUser />;
      case ModalComponents.ADD_ORGANIZER:
        return <AddOrganizer />;
      case ModalComponents.CONFIRM_ORGANIZER:
        return <ConfirmOrganizer />;
      case ModalComponents.CONFIRM_REINVITE:
        return <ConfirmUserReinvite />;
      case ModalComponents.DELETE_TAGS:
        return <DeleteAndUndeletable />;
      case ModalComponents.CONFIRM_UNDO_CALL:
        return <ConfirmUnsend />;
      case ModalComponents.CONFIRM_ACTIVATE_PROJECT:
        return <ConfirmActivateProject />;
      case ModalComponents.CONFIRM_DELETE_PROJECT:
        return <ConfirmDeleteProject />;
      case ModalComponents.CONFIRM_EMAIL_CHANGE:
        return <ConfirmEmailChange />;
      case ModalComponents.CONFIRM_DELETE_TAG:
        return <DeleteTag />;
      case ModalComponents.CONFIRM_SINGLE_TAG_DELETE:
        return <DeleteSingleTag />;
      case ModalComponents.SEND_BULK_USERS_INVITATION:
        return <SendBulkUsersInvitation />;
      case ModalComponents.UNARCHIVE_BULK_ACTION:
        return <UnarchiveBulkAction />;
      case ModalComponents.CONFIRM_UNARCHIVE_PROJECT:
        return <ConfirmUnarchiveProject />;
      case ModalComponents.IMAGE_PREVIEW:
        return <ImagePreview />;

      default:
        return null;
    }
  };

  return <>{renderModalContent(modalState.component)}</>;
}
