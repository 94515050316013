import {takeLatest} from '@redux-saga/core/effects';

import {DeletedProjectsActions} from './actions';
import {getDeletedProjectsSaga} from './getDeletedProjects/saga';
import {searchDeletedProjectsSaga} from './searchDeletedProjects/saga';

export default function* deletedProjectSaga() {
  yield takeLatest(DeletedProjectsActions.GET_DELETED_PROJECTS, getDeletedProjectsSaga);
  yield takeLatest(DeletedProjectsActions.SEARCH_DELETED_PROJECTS, searchDeletedProjectsSaga);
}
