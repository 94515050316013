import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {createProject} from 'services/projectService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {toggleModalAC} from 'store/modal/toggleModal/actionCreator';
import {ModalComponents} from 'typescript/enums/ModalComponents';
import {ICreateNewProject} from 'typescript/interfaces/IProjectActions';
import {SingleProject} from 'typescript/models/SingleProject';

import {addRemoveOrganizersACS} from '../addRemoveOrganizers/actionCreators';
import {getProjectsSagaACS} from '../getProjects/actionCreator';
import newProjectAC from '../newProject/actionCreator';
import {setSingleProjectAC} from '../setSingleProject/actionCreator';

export function* createNewProjectSaga({payload}: ICreateNewProject) {
  const stringUser = localStorage.getItem('user');
  let user;
  if (stringUser) user = JSON.parse(stringUser);
  const newTitle = payload.dto.title.trimStart().trimEnd();
  const newPayload = {...payload.dto, title: newTitle};
  try {
    const response: AxiosResponse<SingleProject> = yield call(createProject, newPayload);

    yield put(addRemoveOrganizersACS({addOrRemove: true, dto: {id: response.data.id, userId: user.id}}));
    yield put(getProjectsSagaACS(payload.filters));
    yield put(setSingleProjectAC({...response.data, organizers: [user]}));

    yield put(newProjectAC(true));
    yield put(toggleModalAC({isOpen: true, component: ModalComponents.PROJECT}));
  } catch (error) {
    yield put(setGlobalNotification({variant: 'error', message: 'Project unsuccessfully created'}));
  }
}
