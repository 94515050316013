import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {postRemainder} from 'services/remaindersService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IAddRemainderDTO} from 'typescript/interfaces/IProjectActions';
import {Remainder} from 'typescript/models/Remainder';

import {setRemainderRedux} from './actionCreator';

export function* addRemainderSaga(action: IAddRemainderDTO) {
  try {
    const response: AxiosResponse<Remainder> = yield call(postRemainder, action.payload);
    yield put(setRemainderRedux(response.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
