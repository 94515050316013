import {takeLatest} from '@redux-saga/core/effects';

import {TagActions} from './actions';
import {getTagDetailsSaga} from './getTagDetails/saga';
import {getTagsSaga} from './getTags/saga';
import {searchTagDetailsSaga} from './searchTagDetails/saga';

export default function* tagSaga() {
  yield takeLatest(TagActions.GET_TAGS, getTagsSaga);
  yield takeLatest(TagActions.GET_TAG_DETAILS, getTagDetailsSaga);
  yield takeLatest(TagActions.SEARCH_TAGS, searchTagDetailsSaga);
}
