import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getInstitution, getInstitutionLogo} from 'services/institutionService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {Institution} from 'typescript/models/Institution';

import {setInstitutionAC, setInstitutionLogoUrl} from './actionCreator';

export function* getInstitutionSaga() {
  try {
    const response: AxiosResponse<Institution> = yield call(getInstitution);
    const urlResponse: AxiosResponse<Blob> = yield call(getInstitutionLogo);

    const url = URL.createObjectURL(urlResponse.data);

    yield put(setInstitutionAC(response.data));
    yield put(setInstitutionLogoUrl(url));
  } catch (error) {
    yield call(handleErrorsSaga, error);
    yield put(setInstitutionLogoUrl(null));
  }
}
