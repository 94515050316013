import {call, put} from 'redux-saga/effects';
import {addMember, removeMember} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IAddRemoveMembers} from 'typescript/interfaces/IProjectActions';

import {getActivityLogACS} from '../getActivityLog/actionCreators';
import {setSingleProjectAC} from '../setSingleProject/actionCreator';

export function* addRemoveMembersSaga({payload}: IAddRemoveMembers) {
  try {
    if (payload.addRemoveMember) yield call(addMember, payload.dto);
    else yield call(removeMember, payload.dto);

    if (payload.singleProject) {
      yield put(setSingleProjectAC(payload.singleProject));
      yield put(getActivityLogACS(payload.singleProject.id));
    }
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
