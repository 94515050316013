import {ISetUploadedDocument, ISetUploadedFrom, IUploadDocument} from 'typescript/interfaces/IDocumentsActions';
import {Document} from 'typescript/models/Document';
import {UploadedFrom} from 'typescript/types/UploadedFrom';

import {DocumentsActions} from '../actions';

export function uploadDocument(payload: IUploadDocument['payload']): IUploadDocument {
  return {
    type: DocumentsActions.UPLOAD_DOCUMENT,
    payload,
  };
}

export function setUploadedDocument(document: Document | null): ISetUploadedDocument {
  return {
    type: DocumentsActions.SET_UPLOADED_DOCUMENT,
    payload: document,
  };
}

export function setUploadedFrom(from: UploadedFrom | null): ISetUploadedFrom {
  return {
    type: DocumentsActions.SET_UPLOADED_FROM,
    payload: from,
  };
}
