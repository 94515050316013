import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {GetTagDetailsDTO} from 'typescript/dto/GetTagDetailsDTO';
import {GetTagsDTO, TagDTO} from 'typescript/dto/TagDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {Tag} from 'typescript/models/Tag';
import {TagDetail} from 'typescript/models/TagDetails';
import httpClient from 'utils/httpClient/httpClient';

import {generateQueryObject} from './Helpers/generateQueryObject';
import {generateTagDetailsQuery} from './Helpers/generateTagDetailsQuery';
import {generateTagQuery} from './Helpers/generateTagQuery';

export function getTags(dto?: GetTagsDTO): AxiosPromise<Tag[]> {
  const query = generateQueryObject(dto, generateTagQuery);
  return httpClient.get(`${API.TAG_SERVICE.BASE}${query}`);
}

export function updateTag(id: number, dto: TagDTO): AxiosPromise<Tag> {
  return httpClient.put(`${API.TAG_SERVICE.BASE}/${id}`, dto);
}

export function deleteTag(id: number): AxiosPromise<Tag> {
  return httpClient.delete(`${API.TAG_SERVICE.BASE}/${id}`);
}

export function deleteTagsBulk(ids: number[]): AxiosPromise<string> {
  return httpClient.post(`${API.TAG_SERVICE.BASE}/bulk/delete`, {ids});
}

export function createTag(dto: TagDTO): AxiosPromise<Tag> {
  return httpClient.post(`${API.TAG_SERVICE.BASE}`, dto);
}

export function getTagDetails(dto?: GetTagDetailsDTO): AxiosPromise<ResponseWithFilters<TagDetail[]>> {
  const query = generateQueryObject(dto, generateTagDetailsQuery);
  return httpClient.get(`${API.TAG_SERVICE.DETAILS}${query}`);
}

export function searchTagDetails(search: string): AxiosPromise<ResponseWithFilters<TagDetail[]>> {
  return httpClient.get(`${API.SEARCH.TAGS}?name=${search}`);
}
