import {call, put} from 'redux-saga/effects';
import {deleteRemainder} from 'services/remaindersService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IDeleteRemainderList} from 'typescript/interfaces/IProjectActions';

import {setRemaindersRedux} from '../getRemainders/actionCreators';

export function* deleteRemainderSaga({payload}: IDeleteRemainderList) {
  const {remainderId, projectId, remainders} = payload;

  try {
    yield call(deleteRemainder, {remainderId, projectId});
    const filteredRemaindersList = remainders.filter((remainder) => remainder.id !== remainderId);
    yield put(setRemaindersRedux(filteredRemaindersList));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
