export enum UserActions {
  GET_USERS = 'GET_USERS',
  SET_USERS = 'SET_USERS',
  CHECKED_USERS = 'CHECKED_USERS',
  SET_PAGINATE_USERS = 'SET_PAGINATE_USERS',
  ENABLE_USER = 'ENABLE_USER',
  DISABLE_USER = 'DISABLE_USER',
  SET_ADD_USERS_DATA = 'SET_ADD_USERS_DATA',
  SET_USER_FILTERS = 'SET_USER_FILTERS',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  SET_USER_DELETE = 'SET_USER_DELETE',
  SET_USER_INVITE_ID = 'SET_USER_INVITE_ID',
  SEARCH_USER = 'SEARCH_USER',
  SET_USER_ACTIVATE_DEACTIVATE = 'SET_USER_ACTIVATE_DEACTIVATE',
  CONFIRM_USER_ID = 'CONFIRM_USER_ID',
  SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS',
  SET_SELECTED_USERS = 'SET_SELECTED_USERS',
}
