import {call, put} from 'redux-saga/effects';
import {deleteProjectComment} from 'services/commentsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IDeleteComment} from 'typescript/interfaces/ICommentsActions';

import {unsetDeletedComment} from './actionCreator';

export function* deleteComment(action: IDeleteComment) {
  const {projectId, commentId} = action.payload;

  try {
    yield call(deleteProjectComment, projectId, commentId);
    yield put(unsetDeletedComment(commentId));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
