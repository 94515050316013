import produce, {castDraft, Draft} from 'immer';
import {NotificationActionsTypes} from 'typescript/interfaces/INotifications';
import {NotificationContent, NotificationCount} from 'typescript/models/Notifications';

import {NotificationActions} from './actions';

export interface IState {
  notifications: NotificationContent[];
  notificationCount: NotificationCount;
}

export const initialState: IState = {
  notifications: [],
  notificationCount: {READ: 0, ALL: 0, UNREAD: 0},
};

const notificationsReducer = (state = initialState, action: NotificationActionsTypes) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case NotificationActions.SET_NOTIFICATIONS:
        draft.notifications = castDraft(action.payload);
        break;

      case NotificationActions.SET_NOTIFICATION_COUNT:
        draft.notificationCount = castDraft(action.payload);
        break;
    }
  });

export default notificationsReducer;
