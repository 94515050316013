import produce, {Draft} from 'immer';
import {IAppActions, IHandleServerErrorsPayload, IGlobalNotificationPayload} from 'typescript/interfaces/IAppActions';
import {ProjectViewType} from 'typescript/types/projectViewType';

import {AppActions} from './actions';

export interface IState {
  axiosError: IHandleServerErrorsPayload;
  fontSize: number;
  showAddUser: boolean;
  projectView: ProjectViewType;
  globalNotification: IGlobalNotificationPayload | null;
  isDropdownOpen: string;
}

export const initialState: IState = {
  axiosError: null,
  fontSize: 14,
  showAddUser: false,
  projectView: 'table',
  globalNotification: null,
  isDropdownOpen: '',
};

const appReducer = (state = initialState, action: IAppActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case AppActions.HANDLE_SERVER_ERRORS:
        draft.axiosError = action.payload;
        break;
      case AppActions.SET_FONT_SIZE:
        draft.fontSize = action.payload;
        break;
      case AppActions.SET_PROJECT_VIEW:
        draft.projectView = action.payload;
        break;
      case AppActions.SET_GLOBAL_NOTIFICATION:
        draft.globalNotification = action.payload;
        break;
      case AppActions.SET_DROPDOWN_OPEN:
        draft.isDropdownOpen = action.payload;
        break;
    }
  });

export default appReducer;
