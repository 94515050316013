import {takeLatest} from 'redux-saga/effects';

import {InstitutionActions} from './actions';
import {getInstitutionSaga} from './get/saga';
import {updateInstitutionLogoSaga, updateInstitutionNameSaga} from './update/saga';

export default function* institutionSaga() {
  yield takeLatest(InstitutionActions.GET_INSTITUTION, getInstitutionSaga);
  yield takeLatest(InstitutionActions.UPDATE_INSTITUTION_NAME, updateInstitutionNameSaga);
  yield takeLatest(InstitutionActions.UPDATE_INSTITUTION_LOGO, updateInstitutionLogoSaga);
}
