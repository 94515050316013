import {Tag} from 'typescript/models/Tag';

import {TagActions} from '../actions';

export function setTagsAC(payload: Tag[]) {
  return {
    type: TagActions.SET_TAGS,
    payload,
  };
}
