import {Routes} from 'constants/routes';

import {AxiosResponse} from 'axios';
import {push} from 'connected-react-router';
import {call, put} from 'redux-saga/effects';
import {setupCheck} from 'services/userService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';

import {setCheckedUserRedux} from './actionCreators';

export function* checkForUsersSaga() {
  try {
    const response: AxiosResponse<boolean> = yield call(setupCheck);
    yield put(setCheckedUserRedux(response.data));
    if (!response.data) yield put(push(Routes.API_REGISTRATION_SETUP));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
