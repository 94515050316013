import {AuthActions} from 'store/auth/actions';
import {ISetUser} from 'typescript/interfaces/IAuthActions';
import {User} from 'typescript/models/User';

export function setUserAC(payload: User | null): ISetUser {
  return {
    type: AuthActions.SET_USER,
    payload,
  };
}
