import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {deleteProjectDocument} from 'services/documentsService';
import {getSingleProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {getActivityLogACS} from 'store/project/getActivityLog/actionCreators';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {IDeleteDocument} from 'typescript/interfaces/IDocumentsActions';
import {SingleProject} from 'typescript/models/SingleProject';

export function* deleteDocument(action: IDeleteDocument) {
  try {
    yield call(deleteProjectDocument, action.payload);
    const response: AxiosResponse<SingleProject> = yield call(getSingleProject, action.payload.projectId);
    yield put(setSingleProjectAC(response.data));
    yield put(getActivityLogACS(action.payload.projectId));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
