import {Routes} from 'constants/routes';

interface IProps {
  token: string | null;
}

export const getLoggedRouteList = ({token}: IProps) => {
  const currentLocation = window.location.pathname;
  let route = '';
  const routeBlackList = [
    '/',
    Routes.LOGIN,
    Routes.REGISTER,
    Routes.RESET_PASSWORD,
    Routes.PASSWORD_CHANGE,
    Routes.API_REGISTRATION_SETUP,
  ];

  const shouldPreviousRouteBeRemebered = !token && !routeBlackList.includes(currentLocation);
  if (shouldPreviousRouteBeRemebered) route = currentLocation;

  if (route) localStorage.setItem('rememberedPage', route);
};
