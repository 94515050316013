import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {searchProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {ISearchProjects} from 'typescript/interfaces/IProjectActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {setProjectLoadingAC} from '../setLoading/actionCreator';
import {setPaginationAC} from '../setPagination/actionCreator';
import {setProjectsAC} from '../setProjects/setProjects';

export function* searchProjectSaga({payload}: ISearchProjects) {
  try {
    yield put(setProjectLoadingAC(true));

    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(searchProjects, payload);
    const {last, first} = response.data;

    yield put(setProjectsAC(response.data.content));

    yield put(setPaginationAC({last, first}));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  } finally {
    yield put(setProjectLoadingAC(false));
  }
}
