import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {ChangeInstitutionLogoDTO} from 'typescript/dto/InstitutionDTO';
import {Institution} from 'typescript/models/Institution';
import httpClient from 'utils/httpClient/httpClient';

export const getInstitution = (): AxiosPromise<Institution> => {
  return httpClient.get(API.SETUP.GET);
};

export const updateInstitutionName = (name: string): AxiosPromise<string> => {
  return httpClient.post(API.SETUP.NAME, name, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateInstitutionLogo = (logo: ChangeInstitutionLogoDTO): AxiosPromise<string> => {
  const data = new FormData();

  const payload = {
    name: logo.name,
    comment: logo.comment || '',
    type: logo.type,
    source: logo.source,
  };

  const json = new Blob([JSON.stringify(payload)], {
    type: 'application/json',
  });

  data.append('document', json);
  data.append('file', logo.file);

  return httpClient.post(API.SETUP.LOGO, data);
};

export const getInstitutionLogo = (): AxiosPromise<Blob> => {
  return httpClient.get(`${API.SETUP.LOGO}/download`, {
    responseType: 'blob',
  });
};
