import {call, put} from 'redux-saga/effects';
import {markAllProjectAsRead} from 'services/notificationService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {getProjectsSagaACS} from 'store/project/getProjects/actionCreator';
import {IMarkAllAsReadProject} from 'typescript/interfaces/INotifications';

import {getNotificationsACS} from '../getNotifications/actionCreators';

export function* markAllAsReadProjectSaga({payload}: IMarkAllAsReadProject) {
  try {
    yield call(markAllProjectAsRead, payload);
    yield put(getProjectsSagaACS(payload.filters));
    yield put(getNotificationsACS({order: 'DESC', sort: 'time'}));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
