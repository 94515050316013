import {ActivityLog} from 'typescript/models/ActivityLog';

import {ProjectActions} from '../actions';

export const getActivityLogACS = (payload: number) => {
  return {type: ProjectActions.GET_ACTIVITY_LOGS, payload};
};

export const setActivityLogRedux = (payload: ActivityLog[]) => {
  return {type: ProjectActions.SET_ACTIVITY_LOGS, payload};
};
