import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {searchUsers} from 'services/userService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {ISearchUsers} from 'typescript/interfaces/IUserActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {Member} from 'typescript/models/Member';

import {paginateUsersAC} from '../setPaginationData/actionCreator';
import {setUsersAC} from '../setUsers/actionCreator';

export function* searchUserSaga({payload}: ISearchUsers) {
  try {
    const response: AxiosResponse<ResponseWithFilters<Member[]>> = yield call(searchUsers, payload);
    const {last, first} = response.data;
    yield put(paginateUsersAC({last, first}));
    yield put(setUsersAC(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
