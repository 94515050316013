import produce, {Draft} from 'immer';
import {IInstitutionActions} from 'typescript/interfaces/IInstitutionActions';
import {Institution} from 'typescript/models/Institution';

import {InstitutionActions} from './actions';

export interface IState {
  institution: Institution | null;
  logoUrl: string | null;
}

export const initialState: IState = {
  institution: null,
  logoUrl: null,
};

const institutionReducer = (state = initialState, action: IInstitutionActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case InstitutionActions.SET_INSTITUTION:
        draft.institution = action.payload;
        break;
      case InstitutionActions.SET_INSTITUTION_LOGO_URL:
        draft.logoUrl = action.payload;
        break;
    }
  });

export default institutionReducer;
