import {call, put} from 'redux-saga/effects';
import {updateUser} from 'services/userService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {getCurrentUserAC} from 'store/auth/getCurrentUser/actionCreator';
import {IUpdateUser} from 'typescript/interfaces/IUserActions';

import {getUsersACS} from '../getUsers/actionCreator';

export function* updateUserSaga({payload}: IUpdateUser) {
  const {data, refreshData} = payload;

  try {
    yield call(updateUser, data);
    yield put(getUsersACS({}));
    if (refreshData) yield put(getCurrentUserAC());
    yield put(setGlobalNotification({variant: 'success', message: 'User edited successfully', icon: 'fa-user'}));
  } catch (error) {
    yield put(setGlobalNotification({variant: 'error', message: 'User edited unsuccessfully'}));
  }
}
