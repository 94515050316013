import {DEFAULT_PAGE_SIZE} from 'constants/pagination';

import produce, {castDraft, Draft} from 'immer';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {ImagePreview} from 'typescript/interfaces/ImagePreview';
import {ProjetcReducerActions} from 'typescript/interfaces/IProjectActions';
import {ActivityLog} from 'typescript/models/ActivityLog';
import {Member} from 'typescript/models/Member';
import {PaginateData} from 'typescript/models/Paginations';
import {RemainderType} from 'typescript/models/RemainderType';
import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from './actions';

export interface IState {
  isNewProject: boolean;
  singleProject: SingleProject | null;
  projects: SingleProject[];
  isLoading: boolean;
  singleDayProjectList: {listOfProjects: SingleProject[]; date: string};
  checkedProjects: number[];
  pagination: PaginateData;
  projectFilters: IGetProjectsDTO;
  selectedOrganizer: Member | null;
  selectedProject: SingleProject | null;
  checkedTags: number[];
  activityLogs: ActivityLog[];
  remainderTypes: RemainderType;
  imagePreview: ImagePreview | null;
}

export const initialState: IState = {
  isNewProject: false,
  singleProject: null,
  projects: [],
  isLoading: false,
  singleDayProjectList: {listOfProjects: [], date: ''},
  checkedProjects: [],
  pagination: {last: false, first: false},
  projectFilters: {page: 0, size: DEFAULT_PAGE_SIZE, active: true, order: 'DESC', sort: 'updatedOn', own: true},
  selectedOrganizer: null,
  selectedProject: null,
  checkedTags: [],
  activityLogs: [],
  remainderTypes: [],
  imagePreview: null,
};

const projectReducer = (state = initialState, action: ProjetcReducerActions) =>
  produce(state, (draft: Draft<IState>) => {
    switch (action.type) {
      case ProjectActions.IS_NEW_PROJECT:
        draft.isNewProject = castDraft(action.payload);
        break;
      case ProjectActions.SET_PROJECTS:
        draft.projects = castDraft(action.payload);
        break;
      case ProjectActions.SET_SINGLE_PROJECT:
        draft.singleProject = castDraft(action.payload);
        break;
      case ProjectActions.SET_LOADING:
        draft.isLoading = castDraft(action.payload);
        break;
      case ProjectActions.SET_CALENDAR_PREVIEW_PROJECT:
        draft.singleDayProjectList.listOfProjects = castDraft(action.payload.listOfProjects);
        draft.singleDayProjectList.date = castDraft(action.payload.date);
        break;
      case ProjectActions.SET_REMAINDERS_LIST:
        if (draft.singleProject) {
          draft.singleProject.remainders = castDraft(action.payload);
        }
        break;
      case ProjectActions.SET_REMAINDERS:
        if (draft.singleProject) {
          draft.singleProject.remainders = castDraft([...draft.singleProject.remainders, action.payload]);
        }
        break;
      case ProjectActions.SET_CHECKED_PROJECTS:
        draft.checkedProjects = castDraft(action.payload);
        break;
      case ProjectActions.SET_PAGINATION:
        draft.pagination = castDraft(action.payload);
        break;
      case ProjectActions.SET_PROJECT_FILTERS:
        draft.projectFilters = castDraft(action.payload);
        break;
      case ProjectActions.SET_FAVORITE_PROJECT:
        draft.projects = draft.projects.map((project) => {
          if (project.id === action.payload) project.favorite = true;

          return project;
        });

        if (draft.singleProject) draft.singleProject.favorite = true;

        break;
      case ProjectActions.UNSET_FAVORITE_PROJECT:
        draft.projects = draft.projects.map((project) => {
          if (project.id === action.payload) project.favorite = false;

          return project;
        });

        if (draft.singleProject) draft.singleProject.favorite = false;
        break;
      case ProjectActions.SET_SELECTED_ORGANIZER:
        draft.selectedOrganizer = action.payload;
        break;
      case ProjectActions.SET_SELECTED_PROJECT:
        draft.selectedProject = action.payload;
        break;
      case ProjectActions.SET_CHECKED_PROJECT_TAGS:
        draft.checkedTags = action.payload;
        break;
      case ProjectActions.SET_ACTIVITY_LOGS:
        draft.activityLogs = action.payload;
        break;
      case ProjectActions.SET_UPDATED_PROJECT:
        draft.projects = draft.projects.map((project) => (project.id === action.payload.id ? action.payload : project));
        break;
      case ProjectActions.SET_REMAINDER_TYPE:
        draft.remainderTypes = castDraft(action.payload);
        break;
      case ProjectActions.SET_IMAGE_PREVIEW:
        draft.imagePreview = action.payload;
        break;
    }
  });

export default projectReducer;
