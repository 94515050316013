import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {getRemainders} from 'services/remaindersService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetRemaindersList} from 'typescript/interfaces/IProjectActions';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {Remainder} from 'typescript/models/Remainder';

import {setRemaindersRedux} from './actionCreators';

export function* getRemaindersListSaga(action: IGetRemaindersList) {
  try {
    const response: AxiosResponse<ResponseWithFilters<Remainder[]>> = yield call(getRemainders, action.payload);
    yield put(setRemaindersRedux(response.data.content));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
