import {takeLatest} from 'redux-saga/effects';

import {ReactionsActions} from './actions';
import {createReaction} from './add/saga';
import {deleteReaction} from './delete/saga';

export default function* reactionsSaga() {
  yield takeLatest(ReactionsActions.CREATE_REACTION, createReaction);
  yield takeLatest(ReactionsActions.DELETE_REACTION, deleteReaction);
}
