import {call, put} from 'redux-saga/effects';
import {unarchiveProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import getArchivedProjectsACS from 'store/archive/getArchivedProjects/actionCreator';
import {IUnarchiveProject} from 'typescript/interfaces/IArchiveActions';

export function* unarchiveSingleProject(action: IUnarchiveProject) {
  const {id, filters} = action.payload;

  try {
    yield call(unarchiveProject, id);

    yield put(getArchivedProjectsACS(filters));
    yield put(
      setGlobalNotification({
        variant: 'success',
        message: 'Project unarchived successfully',
        icon: 'fa-credit-card-blank',
      }),
    );
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
