import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {uploadProjectDocument} from 'services/documentsService';
import {getSingleProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {getActivityLogACS} from 'store/project/getActivityLog/actionCreators';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {IUploadDocument} from 'typescript/interfaces/IDocumentsActions';
import {Document} from 'typescript/models/Document';
import {SingleProject} from 'typescript/models/SingleProject';

import {setUploadedFrom, setUploadedDocument} from './actionCreator';

export function* uploadDocument({payload}: IUploadDocument) {
  const {from, projectId} = payload;

  try {
    const documentResponse: AxiosResponse<Document> = yield call(uploadProjectDocument, payload);
    const projectResponse: AxiosResponse<SingleProject> = yield call(getSingleProject, projectId);

    yield put(setUploadedDocument(documentResponse.data));

    if (from) yield put(setUploadedFrom(from));
    yield put(getActivityLogACS(projectId));
    yield put(setSingleProjectAC(projectResponse.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
