export enum CommentsActions {
  GET_COMMENTS = 'GET_COMMENTS',
  SET_COMMENTS = 'SET_COMMENTS',
  CREATE_COMMENT = 'CREATE_COMMENT',
  SET_CREATED_COMMENT = 'SET_CREATED_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  UNSET_DELETED_COMMENT = 'UNSET_DELETED_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  SET_EDITED_COMMENT = 'SET_EDITED_COMMENT',
  SET_IS_REPLYING = 'SET_IS_REPLYING',
  SET_PARENT_ID = 'SET_PARENT_ID',
}
