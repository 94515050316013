import {ISetUsers} from 'typescript/interfaces/IUserActions';
import {Member} from 'typescript/models/Member';

import {UserActions} from '../actions';

export function setUsersAC(payload: Member[]): ISetUsers {
  return {
    type: UserActions.SET_USERS,
    payload,
  };
}
