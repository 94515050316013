import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {createProjectComment} from 'services/commentsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {getActivityLogACS} from 'store/project/getActivityLog/actionCreators';
import {ICreateComment} from 'typescript/interfaces/ICommentsActions';

import {setCreatedComment} from './actionCreator';

export function* createComment(action: ICreateComment) {
  const {id, data} = action.payload;

  try {
    const response: AxiosResponse<Comment> = yield call(createProjectComment, id, data);

    yield put(setCreatedComment(response.data));
    yield put(getActivityLogACS(id));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
