import {EditCommentDTO} from 'typescript/dto/EditCommentDTO';
import {IEditComment, ISetEditedComment} from 'typescript/interfaces/ICommentsActions';
import {Comment} from 'typescript/models/Comment';

import {CommentsActions} from '../actions';

export function editComment(projectId: number, commentId: number, data: EditCommentDTO): IEditComment {
  return {
    type: CommentsActions.EDIT_COMMENT,
    payload: {projectId, commentId, data},
  };
}

export function setEditedComment(data: Comment): ISetEditedComment {
  return {
    type: CommentsActions.SET_EDITED_COMMENT,
    payload: data,
  };
}
