import {AxiosResponse} from 'axios';
import {put, call} from 'redux-saga/effects';
import {getProjects} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {SingleProject} from 'typescript/models/SingleProject';

import {DeletedProjectsActions} from '../actions';
import {setDeletedProjectsAC} from '../setDeletedProjects/actionCreator';
import {setDeletedProjectsLoadingAC} from '../setDeletedProjectsLoading/actionCreator';
import {setDeletedProjectsPaginationAC} from '../setDeletedProjectsPagination/actionCreator';

export function* getDeletedProjectsSaga(action: {
  type: DeletedProjectsActions.GET_DELETED_PROJECTS;
  payload: IGetProjectsDTO;
}) {
  try {
    yield put(setDeletedProjectsLoadingAC(true));
    const response: AxiosResponse<ResponseWithFilters<SingleProject[]>> = yield call(getProjects, action.payload);
    const {last, first, totalPages} = response.data;

    yield put(setDeletedProjectsAC(response.data.content));

    yield put(setDeletedProjectsPaginationAC({last, first, totalPages}));
    yield put(setDeletedProjectsLoadingAC(false));
  } catch (error) {
    handleErrorsSaga(error);
  }
}
