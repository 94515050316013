import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';

const queryCases = [
  'page',
  'size',
  'order',
  'ids',
  'title',
  'active',
  'dateFrom',
  'dateTo',
  'year',
  'month',
  'day',
  'name',
  'sort',
  'tagIds',
  'organizerIds',
  'memberIds',
  'own',
  'deleted',
];

export const generateProjectQueries = (dto: IGetProjectsDTO) => {
  const convertedToMatrix = Object.entries(dto).map((item) => {
    const filteredQuery = queryCases
      .map((queryCase) => {
        let query = '';
        if (queryCase === item[0]) query += `&${queryCase}=${item[1]}`;
        return query;
      })
      .filter((x) => x !== '');
    return filteredQuery;
  });

  return convertedToMatrix.flat().join('').slice(1);
};
