import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from '../actions';

export function setSingleProjectAC(payload: SingleProject | null) {
  return {
    type: ProjectActions.SET_SINGLE_PROJECT,
    payload,
  };
}
