import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {editProjectComment} from 'services/commentsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IEditComment} from 'typescript/interfaces/ICommentsActions';
import {Comment} from 'typescript/models/Comment';

import {setEditedComment} from './actionCreator';

export function* editComment(action: IEditComment) {
  const {projectId, commentId, data} = action.payload;

  try {
    const response: AxiosResponse<Comment> = yield call(editProjectComment, projectId, commentId, data);

    yield put(setEditedComment(response.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
