import {GetUsersDTO} from 'typescript/dto/GetUsersDTO';

const queryCases = ['page', 'size', 'order', 'sort', 'ids', 'email', 'position', 'unit', 'organization', 'active'];

export const generateUserQuery = (dto: GetUsersDTO) => {
  const convertedToMatrix = Object.entries(dto).map((item) => {
    const filteredQuery = queryCases
      .map((queryCase) => {
        let query = '';
        if (queryCase === item[0]) query += `&${queryCase}=${item[1]}`;
        return query;
      })
      .filter((x) => x !== '');
    return filteredQuery;
  });

  return convertedToMatrix.flat().join('').slice(1);
};
